<template>
  <a-modal
    v-model="configPlaceVisible"
    title="配置预算到广告位"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="820px"
  >
    <div class="footer" slot="footer">
      <div>
        <a-button
          v-if="+platAccountInfo.authSts === 0"
          type="link"
          @click="handleClickImpowerBtn"
        >去获取更多流量！</a-button
        >
        <a-button
          v-else-if="+platAccountInfo.authSts === 1"
          type="link"
          @click="handleSkip"
        >已授权，查看审核状态</a-button
        >
      </div>
      <div class="btn">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSubmit">确定</a-button>
      </div>
    </div>
    <div class="config-place-modal-wrapper">
      <div class="config-place-header">
        <div class="info-item">
          <span class="label">广告平台：</span>
          <span class="name">{{ platInfo.name }}</span>
        </div>
        <div class="info-item">
          <span class="label">所属账号：</span>
          <span class="name">{{ platAccountInfo.name }}</span>
        </div>
        <div class="info-item">
          <span class="label">广告源名称：</span>
          <span class="name">{{ dspPlaceInfo.name }}</span>
        </div>
      </div>
      <div class="place-title">广告位列表</div>
      <div class="config-place-main">
        <div class="config-place-main-left right-border">
          <div class="search-wrapper">
            <a-input v-model.trim="searchKey" placeholder="输入广告位名称/应用名称/ID搜索"></a-input>
          </div>
          <div class="app-list-wrapper">
            <div class="app-item" v-for="app in searchList" :key="app.appId">
              <div class="app-box" @click="changeIsExpand($event, app)">
                <a-checkbox
                  class="app-checkbox"
                  :indeterminate="app.indeterminate"
                  :checked="app.checkAll"
                  @click="changeAppChecked($event, app)"
                >
                  {{ app.appName }}<AndroidSvg class="icon" v-if="app.os === 1" /><IosSvg class="icon" v-else />
                </a-checkbox>
                <a-icon :type="!app.isExpand ? 'down' : 'up'" class="app-arrow icon" />
              </div>
              <div class="place-list" v-if="app.isExpand">
                <a-checkbox
                  class="place-checkbox"
                  v-for="place in app.childList"
                  :key="place.placeId"
                  :checked="place.checked"
                  @change="changePlaceChecked($event, app, place)"
                >
                  <span :title="`ID：${place.placeId}`">{{ place.placeName }}</span>
                </a-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="config-place-main-right">
          <div class="config-place-main-right-header">
            <span>已选{{ configuredList.length }}项</span>
            <a-button type="link" size="small" @click="handleClearAll">全部清除</a-button>
          </div>
          <div class="check-list">
            <div v-for="(place, index) in configuredList" :key="place.placeId" class="check-item">
              <div class="check-place-name">{{ place.placeName }}</div>
              <a-icon
                v-if="!+place.configured"
                @click.stop="handleCirclePlace(index)"
                type="close-circle"
                class="close-icon"
              />
              <a-popconfirm
                v-if="+place.configured"
                title="确认移除已配置广告位?"
                ok-text="是"
                cancel-text="否"
                @confirm="handleCirclePlace(index)"
              >
                <a-icon type="close-circle" class="close-icon" />
              </a-popconfirm>
              <a-icon @click="handleGetTestList(place)" type="setting" class="setting-icon" />
            </div>
            <m-empty v-if="configuredList.length === 0" imgHeight="100px">
              <template #description> 暂未选择广告位 </template>
            </m-empty>
          </div>
        </div>
      </div>
    </div>
    <selectTestModal
      v-if="selectTestVisible"
      :visible="selectTestVisible"
      @modalCancel="selectTestVisible = false"
      @handleSelectTest="handleSelectTest"
      :query="currentPlace"
      :placeConfig="placeConfigInfo"
    />
  </a-modal>
</template>

<script>
import { getRtbTreeData, configured2 } from '@/api/aggregate'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import selectTestModal from '../selectTestModal'
import { updateAuthSts } from '@/api/advertisement'

export default {
  name: 'BatchConfigPlaceModal',
  components: { IosSvg, AndroidSvg, selectTestModal },
  data () {
    return {
      configuredList: [],
      allAppList: [],
      searchKey: undefined,
      selectTestVisible: false,
      currentPlace: {},
      selectTestList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    platInfo: {
      type: Object,
      default: () => ({})
    },
    dspPlaceInfo: {
      type: Object,
      default: () => ({})
    },
    platAccountInfo: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          // 每次打开弹窗时，清空搜索字段
          this.searchKey = ''
          this.getRtbTreeList()
          // this.selectTestList = []
        }
      }
    }
  },
  computed: {
    // 左侧树形列表
    searchList () {
      if (!this.searchKey) {
        // 搜索字段为空，返回全部数据
        return this.allAppList
      } else {
        // 字段搜索返回相应的应用和广告位
        let arr = []
        this.allAppList.forEach((item) => {
          // 应用中的广告位列表是否包含搜索字段的广告位
          const placeList = item.childList.filter((place) => place.placeName.includes(this.searchKey) || place.placeId.includes(this.searchKey))
          if (item.appName.includes(this.searchKey) || item.appId.includes(this.searchKey)) {
            arr = [...arr, item]
          } else if (placeList.length !== 0) {
            item = { ...item, childList: placeList }
            arr = [...arr, item]
          }
        })
        return arr
      }
    },
    configPlaceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleSelectTest (info) {
      const hasPlace = this.selectTestList.findIndex((item) => item.placeId === this.currentPlace.placeId)
      if (hasPlace !== -1) {
        // 存在该广告位，则删除
        this.selectTestList.splice(hasPlace, 1)
      }
      this.selectTestList.push({
        placeId: this.currentPlace.placeId,
        groupList: info
      })
      this.selectTestVisible = false
    },
    handleGetTestList (place) {
      this.placeConfigInfo = this.selectTestList.find((item) => item.placeId === place.placeId) || {}
      this.currentPlace = place
      this.selectTestVisible = true
    },
    /**
     * 应用多选框点击事件
     * @param {*} e 应用点击事件
     * @param {*} app 应用信息
     */
    changeAppChecked (e, app) {
      // 选中状态
      const checked = e.target.checked
      app.childList.forEach((place) => {
        if (checked && !place.checked) {
          this.configuredList = [...this.configuredList, place]
        } else if (!checked && place.checked) {
          this.configuredList = this.configuredList.filter((item) => item.placeId !== place.placeId)
        }
        place.checked = checked
      })
      this.handleCheck()
    },
    /**
     * 广告位多选框点击事件
     * @param {*} e 应用多选框点击事件
     * @param {*} app 应用对象
     * @param {*} place 广告位对象
     */
    changePlaceChecked (e, app, place) {
      // 选中状态
      const checked = e.target.checked
      if (checked) {
        // 选中状态
        this.configuredList = [...this.configuredList, place]
      } else {
        // 未选中状态
        this.configuredList.splice(
          this.configuredList.findIndex((item) => item.placeId === place.placeId),
          1
        )
      }
      this.handleCheck()
    },
    // 点击展开图标
    changeIsExpand (e, app) {
      if (e.target.classList[0] === 'app-box' || e.target.tagName === 'svg' || e.target.tagName === 'path') {
        const appId = app.appId
        app = this.allAppList.find((item) => item.appId === appId)
        app.isExpand = !app.isExpand
      }
    },
    // 获取应用广告位树形数据和当前已配置广告位列表
    async getRtbTreeList () {
      const { data = {} } = await getRtbTreeData({ id: this.dspPlaceInfo.id })
      const { configuredList = [], items = [] } = data
      this.configuredList = configuredList
      items.forEach((item) => {
        this.$set(item, 'isExpand', false)
      })
      this.allAppList = items
      this.handleCheck()
      this.initSelectTestList()
    },
    initSelectTestList () {
      this.selectTestList = []
      this.configuredList.forEach((item) => {
        const placeItem = {
          placeId: item.placeId,
          groupList: []
        }
        this.selectTestList.push(placeItem)
        const { configResponseVo = {} } = item
        const { groups = [] } = configResponseVo
        groups.forEach((group) => {
          const { abTests = [] } = group
          if (abTests.length === 1) {
            placeItem.groupList.push({
              groupId: group.id,
              testIdList: abTests.filter((item) => +item.configured === 1).map((test) => test.testId)
            })
          } else if (abTests.length > 1) {
            placeItem.groupList.push({
              groupId: group.id,
              testIdList: abTests.filter((item) => +item.configured === 1).map((test) => test.testId).filter((testId) => testId !== 'default')
            })
          }
        })
        // 过滤掉test分组未选的空项
        placeItem.groupList = placeItem.groupList.filter((item) => item.testIdList.length > 0)
      })
    },
    // 处理选中或取消广告位后，树形结构数据的变化，统一处理，只需完成已勾选广告位
    handleCheck () {
      // 选中的广告位id列表
      const checkedList = this.configuredList.map((item) => item.placeId)
      this.allAppList.forEach((app, index) => {
        ;(app.childList || []).forEach((place) => {
          // 广告位选中状态
          this.$set(place, 'checked', checkedList.includes(place.placeId))
        })
        // 应用选中状态
        this.$set(
          app,
          'indeterminate',
          app.childList.filter((item) => item.checked).length > 0 &&
            app.childList.filter((item) => item.checked).length < app.childList.length
        )
        // 应用全选状态
        this.$set(app, 'checkAll', app.childList.filter((item) => item.checked).length === app.childList.length)
        // 应用是否展开
        this.$set(app, 'isExpand', !!app.isExpand)
      })
    },
    // 删除选中惦记事件icon
    handleCirclePlace (index) {
      this.configuredList.splice(index, 1)
      this.handleCheck()
    },
    // 删掉所有选中
    handleClearAll () {
      this.configuredList = []
      this.handleCheck()
    },
    handleCancel () {
      this.configPlaceVisible = false
    },
    async handleSubmit () {
      const placeIdList = this.configuredList.map((item) => item.placeId)
      const placeList = []
      placeIdList.forEach((item) => {
        const hasPlace = this.selectTestList.findIndex((child) => child.placeId === item)
        placeList.push({
          placeId: item,
          groupList: hasPlace === -1 ? [] : this.selectTestList[hasPlace].groupList
        })
      })
      const resp = await configured2({
        placeList,
        id: this.dspPlaceInfo.id
      })
      if (resp.code === 200) {
        this.$emit('configPlaceSuccess')
      }
    },
    handleSkip () {
      this.$router.push({
        path: '/advertisement/sonfig',
        query: {
          accountId: this.platAccountInfo.id
        }
      })
    },
    handleClickImpowerBtn () {
      const id = this.dspPlaceInfo.platAccountId
      const _this = this
      this.$confirm({
        title: '提示',
        maskClosable: true,
        content: () => {
          return (
            <div class="method-modal" style="height:140px;display:flex;padding: 10px 30px">
              <div>
                <a-icon
                  style="color: #fa8b15;font-size: 22px;margin-right: 20px;line-height:80px"
                  type="exclamation-circle"
                />
              </div>
              <div>
                <div class="desc">
                  {`平台帮助有充足预算的开发者实现更多预算消耗，可通过本平台购买流量，该功能授权后，请联系商务经理进行预算审核，合同签署及预充值流程。`}
                </div>
                <div style='line-height:40px'>是否开启当前授权？</div>
                <div class="modal-contact-wrapper">
                  <div class="contact-item">
                    <span class="label">商务联系人：</span>
                    <a-popover>
                      <a class="value name">王慧</a>
                      <template slot="content">
                        <img style='width:200px;' src={require('@/assets/images/contacts/contact-wx.jpg')}/>
                      </template>
                    </a-popover>
                  </div>
                  <div class="contact-item">
                    <span class="label">联系电话：</span>
                    <span class="value">18911552435</span>
                  </div>
                </div>
              </div>
            </div>
          )
        },
        okText: '授权',
        cancelText: '取消',
        onOk: () => {
          updateAuthSts({ id }).then((res) => {
            _this.$message.success('授权成功')
            this.platAccountInfo.authSts = 1
            _this.$emit('updateData')
          })
        },
        onCancel: () => {},
        icon: () => {
          return ''
        },
        class: 'method-modal-wrapper',
        width: 600
      })
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: space-between;
}
.config-place-modal-wrapper {
  user-select: none;
  padding: 20px;
  .config-place-header {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 20px;
    .info-item {
      margin-right: 40px;
      font-size: 14px;
      .label {
        font-weight: 600;
      }
    }
  }
  .place-title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    margin: 20px 0;
  }
  .config-place-main {
    border: 1px solid #e8e8e8;
    height: 380px;
    display: flex;
    .config-place-main-left,
    .config-place-main-right {
      flex-grow: 1;
      flex-shrink: 1;
      width: 50%;
      &.right-border {
        border-right: 1px solid #e8e8e8;
      }
    }
    .config-place-main-left {
      display: flex;
      flex-direction: column;
      .search-wrapper {
        min-height: 55px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
      }
      .app-list-wrapper {
        flex-grow: 1;
        height: 0;
        overflow-y: auto;
        padding: 10px 20px;
        .app-item {
          border-bottom: 1px solid #e8e8e8;
          padding: 5px 0 10px 0;
          .app-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .app-checkbox {
              margin-top: 10px;
              margin-bottom: 10px;
            }
            .icon {
              width: 14px;
              height: 14px;
              margin-left: 5px;
              &.app-arrow {
                cursor: pointer;
              }
            }
          }
          .place-list {
            display: flex;
            flex-direction: column;
            .place-checkbox {
              margin-left: 20px;
              margin-top: 5px;
              margin-bottom: 5px;
              padding: 0;
            }
          }
        }
      }
    }
    .config-place-main-right {
      display: flex;
      flex-direction: column;
      .config-place-main-right-header {
        min-height: 55px;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
      }
      .check-list {
        height: 0;
        flex-grow: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .check-item {
          display: flex;
          align-items: center;
          padding: 8px 30px;
          .check-place-name {
            width: 250px;
            height: 34px;
            background: #eaefff;
            line-height: 34px;
            padding-left: 20px;
            border-radius: 10px;
          }
          .close-icon,
          .setting-icon {
            margin-left: 10px;
            color: #ccc;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
::v-deep {
  .modal-contact-wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
    .contact-item {
      margin-right: 10px;
      font-size: 13px;
      .label {
        font-weight: 600;
      }
      .value{
        &.name{
          cursor: pointer;
        }
      }
    }
  }
}
</style>
